.box {
  padding: 3px 12px;
  color: white;
  background-color: #000000;
  display: block;
  border-left: solid 1px #555555;
}
.box.noborder {
  border: 0;
}

.box-prelude {
  margin: 7px 0;
  font-size: 14pt;
  font-family: "Playfair Display";
  color: #B09080;
}
.box-prelude a.problems-link, .box-prelude a.or-maybe-link, .box-prelude a.angle-link, .box-prelude a.new-word-link, .box-prelude a.question-link, .box-prelude a.long-read-link {
  font-weight: inherit;
}

.box-header {
  margin: 10px 0;
  font-size: 18pt;
  font-family: "Perpetua";
  color: #E0FFE0;
}

.box-outside, .box-inside {
  margin: 10px 0;
  font-size: 12pt;
  font-family: "Garamond";
}

.box-coda {
  margin: 10px 0;
  font-size: 9pt;
  font-family: "Garamond";
}

.box-collapse-button {
  color: #CCCCCC;
  background-color: #444444;
  font-family: "Perpetua";
  font-variant-caps: small-caps;
  padding: 5px;
  font-size: 16pt;
}

