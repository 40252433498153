#answer-title {
  margin-bottom: 0;
  color: #FFFFDD;
}

#answer-subtitle {
  margin-top: 2px;
  padding-left: 10px;
  font-size: 14pt;
}

