.App {
  text-align: center;
}

.App hr {
  color: #FFFFFF88;
}

.App a, .link {
  text-decoration: none;
  font-weight: bold;
  color: #4455FF;
}

@media(max-width: 800px) {
  .App .preview-box {
    display: none;
  }
}
