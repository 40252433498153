@font-face {
  font-family: "Playfair Display";
  font-weight: "bold";
  font-style: "italic";
  src: url('./fonts/Playfair_Display/static/PlayfairDisplay-BoldItalic.ttf');
}
@font-face {
  font-family: "Playfair Display";
  font-style: "italic";
  src: url('./fonts/Playfair_Display/static/PlayfairDisplay-Italic.ttf');
}
@font-face {
  font-family: "Playfair Display";
  font-weight: "bold";
  src: url('./fonts/Playfair_Display/static/PlayfairDisplay-Bold.ttf');
}
@font-face {
  font-family: "Playfair Display";
  src: url('./fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: garamond;
  background-color: #000000;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
