#menu-wrapper {
  clear: both;
  padding-top: 5px;
}

#menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.menu-item {
  display: inline-block;
}

.back {
  float: right;
  margin-right: 5px;
}

.section-title {
  text-align: left;
  float: left;
  margin-right: 5px;
}
.section-title.problems-title, .buttonbox.menu-problems button {
  color: #FF2230;
}
.section-title.ormaybe-title, .buttonbox.menu-ormaybe button {
  color: #FFAA44;
}
.section-title.angles-title, .buttonbox.menu-angles button {
  color: #FFFF22;
}
.section-title.neologisms-title, .buttonbox.menu-neologisms button {
  color: #30FF22;
}
.section-title.faq-title, .buttonbox.menu-faq button {
  color: #22FFFF;
}
.section-title.longreads-title, .buttonbox.menu-longreads button {
  color: #FF99FF;
}

.page {
  text-align: left;
}
.page, #page-top {
  margin: auto;
  max-width: 1400px;
}
.page span.warning-span {
  color: #FF99AA;
  font-size: smaller;
}
.page .backbuttons {
  text-align: center;
}
.page .page-footnote {
  font-size: smaller;
}
