.or-maybe-box > .box > .box-header {
  color: #FFCC77;
}

.or-maybe-box {
  text-align: center;
  margin-bottom: 7px;
}

.or-maybe-box > .box > .box-prelude {
  font-size: 18pt;
}

.or-maybe-box > .box > .box-inside, .or-maybe-box > .box > .box-coda {
  text-align: left;
}

.page > .or-maybe-box > .box > .box-inside, .or-maybes-list > .or-maybe-box > .box > .box-inside {
  margin: auto;
  max-width: 700px;
}
.page > .or-maybe-box > .box > .box-coda, .or-maybes-list > .or-maybe-box > .box > .box-coda {
  margin: auto;
  max-width: 700px;
}

.page > .or-maybe-box > .box > .box-outside, .or-maybes-list > .or-maybe-box > .box > .box-outside {
  margin: 0 auto 10px auto;
  max-width: 900px;
}

