#header {
  width: 100%;
  padding-top: 30px;
  color: white;
  background-color: #282c34;
  display: block;
}
@media (max-width: 800px) {
  #header {
    padding-top: 0;
    margin-top: 0;
  }
}

#header h1 {
  margin-top: 0;
  font-size: 36pt;
  font-variant-caps: small-caps;
  font-family: "Perpetua";
  margin-bottom: 3px;
  padding-bottom: 0;
}
@media (max-width: 800px) {
  #header h1 {
    font-size: 30pt;
  }
}

#header p.etymology {
  color: #888888;
  margin-top: 0;
  padding-top: 0;
  font-family: "Playfair Display";
}

#header h2 {
  width: fit-content;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 4px;
  font-size: 24pt;
  font-weight: regular;
  font-family: "Courier";
}

@media (max-width: 800px) {
  #header h2 {
    margin-top: 10px;
    font-size: 20pt;
  }
}

.header-bottom {
  width: 100%;
  min-height: 50px;
  background-image: linear-gradient(#282c34, #000000);
  vertical-align: center;
}
.why-random {
  text-align: left;
  float: left;
  font-size: 11pt;
  padding-left: 10px;
}
.footnote {
  display: inline-block;
  font-size: 9pt;
  text-align: right;
  float: right;
}

#header .subline, {
  margin: 15px;
}
@media (max-width: 800px) {
  #header .subline {
    margin: 4px;
  }
}
