#gist-title {
  margin-bottom: 0;
  color: #DDFFDD
}

#gist-subtitle {
  margin-top: 2px;
  padding-left: 10px;
  font-size: 14pt;
}

#gist-warning {
  font-family: "Playfair Display";
  font-size: 16pt;
}


