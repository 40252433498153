#warning-div {
  max-width: 1200px;
  margin: auto;
}
#warning-div > .box {
  margin-bottom: 10px;
  border-bottom: #555555 1px solid;
}
#warning-div > .box > .box-coda {
  text-align: left;
}

#disclaimer-header {
  text-decoration: underline;
  margin-bottom: 5px;
}
#disclaimer {
  text-align: left;
  margin: 0 auto 10px auto;
  max-width: 1200px
}
