span.goodidea {
  color: #7890FF;
  font-weight: bold;
}

span.badidea {
  color: #FF7070;
  font-weight: bold;
}

.maintext {
  clear: both;
}
