.preview-box {
  width: 70%;
  min-width: 400px;
  position: absolute;
  color: white;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  z-index: 10;
}

.preview-box .box {
  margin: 0;
  padding: 4;
  border: white 3px double;
  border-radius: 25px;
}

.preview-box a.problems-link, .preview-box a.or-maybe-link, .preview-box a.angle-link, .preview-box a.new-word-link, .preview-box a.question-link, .preview-box a.long-read-link {
  font-weight: bold;
}

