.word-box {
  margin: 10px 0px;
}
.intro-words a, .intro-words .link {
  color: #22FF30;
}

.word-box > .box >.box-inside > span {
  color: #BBBBBB;
  font-family: "Playfair Display";
}
.buttonbox.all-button {
  background-color: #339933;
}
.buttonbox.only-critical-button {
  background-color: #996633;
}
h2.all-header, h2.only-critical-header {
  margin-bottom: 0,0
}
h2.all-header {
  color: #44FF44;
}
h2.only-critical-header {
  color: #FFBB44;
}

