.buttonbox {
  width: fit-content;
  margin: 5px;
  padding: 2px;
  color: #dddddd;
  background-color: #333333;
  display: inline-block;
}

.buttonbox-header, .buttonbox-footer {
  margin: 7px 0;
  font-size: 14pt;
  font-family: "Playfair Display";
  color: #B09080;
}

.buttonbox button {
  min-width: min-content;
  max-width: 300px;
  margin: 5px 10px;
  font-size: 18pt;
  font-family: "Perpetua";
  color: #E0FFE0;
  background-color: #333333;
}
